.become-member-infinia-form {
    border: 1px solid gray;
    padding: 50px 150px;
    margin: 50px;
}

.form-control {
    margin-bottom: 25px;
}

a.cm-footer-link.cm-link-serif.footer-links.hover-class.lets-chat-link {
    text-decoration: none;
    color: #525252;
}

a.cm-footer-link.cm-link-serif.footer-links.toll-free {
    text-decoration: none;
    color: #525252;
}

.footer-links img {
    margin-right: 5px;
    display: inline-block;
    height: 14px;
}

.form-check-label {
    margin-bottom: 0;
    margin-left: 20px;
}

.form-control {
    margin-bottom: 25px;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 0px;
    outline: none;
    padding: 0px;
}

.form-control:focus {
    border-bottom: 1px solid gray;
    box-shadow: none;
}

.cm-button {
    min-width: 168px;
    padding: 15px 20px;
    font-family: "ProximaNova", sans-serif;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    color: #a98e4c;
    background: #fff;
    box-sizing: border-box;
    border: 0;
    cursor: pointer;
    border: 0;
    text-decoration: none;
    display: inline-block;
    transition: opacity 0.3s;
    text-align: center;
}

.cm-button:hover {
    text-decoration: none;
    background: #c4b67e;
}

.cm-button.primary {
    color: #fff;
    background: #c4b67e;
}

.become-member-form p {
    font-family: "ProximaNova", sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #000;
}

.footer-text p {
    font-family: "ProximaNova", sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #b1aaaa;
    margin-top: 20px;
}

.errorMsg {
    display: none;
}

.form-check-input {
    position: absolute;
    margin-top: .1rem;
    margin-left: -1.25rem;
}


.cm-select>i.arrow-down {
    position: absolute;
    right: 17px;
    top: calc(51% - 14px);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #000;
}

.dinner-cntry-code>i.arrow-down {
    position: absolute;
    right: 3px;
    top: calc(51% - 4px);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #000;
}

.cm-select.dinner-cntry-code.cm-input-item {
    margin-top: 9px;

}

.cm-input {
    border: 0;
    border-bottom: 1px solid gray;

}

ul#dropdown-title {
    top: calc(100% - 27px);
}

.cm-input {

    color: #6c6c6c !important;
}


::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #6c6c6c !important;
    font-size: 16px !important;
}

button.cm-button.get-started-button.primary.mt-25-sm.cm-wide-mobile.mt-5,
button.cm-button.infinia-button.primary.mt-30.mt-25-sm.cm-wide-mobile.mt-5 {

    display: block;
}

button#submitBecomeMemberBtnDiners,
button#submitBecomeMemberBtnInfinia {
    margin-top: 25px !important;
}

/* select {
    padding: 10px;
    padding-right: 30px;
    background-image: url('https://image.flaticon.com/icons/png/512/60/60995.png');
    background-repeat: no-repeat, repeat;
    background-position: right .4em top 50%, 0 0;
    background-size: .5em auto, 100%;
} */

.mt_20 {
    margin-top: 20px;
}

.rw360Form {
    box-shadow: 0px 1px 3px 3px #dedede;
    margin: 0 auto;
    padding: 0px 100px;
    box-sizing: border-box;
    max-width: 960px;
}

.justify_align {
    text-align: justify;
    font-size: 16px;
}

.justify_align a {
    color: #c4b67e;
    text-decoration: underline;

}

::placeholder {
    font-family: ProximaNova;
    font-size: 16px;
}

.captcha img {
    width: 300px;
    margin: 30px 0px;
}

@media (max-width: 600px) {
    .rw360Form {
        box-shadow: 0px 1px 3px 3px #dedede;
        margin: 0 auto;
        padding: 0px 15px;
        box-sizing: border-box;
        margin: 15px;
    }

    .become-member-form {
        border: none;
        padding: 10px;
        margin: 0px;
    }

    button#submitBecomeMemberBtnDiners,
    button#submitBecomeMemberBtnInfinia {
        margin-top: 25px !important;
        margin-bottom: 20px;
    }

    h3.text-center.mb-5 {
        margin-top: 20px;

    }

    .col-md-2.col-4.cm-select.cm-input-item {
        padding-right: 0px;
        margin-top: 6px;
    }

    .cm-select>i.arrow-down {
        position: absolute;
        right: 6px;
        top: calc(51% - 15px);
        width: 0;
    }

    p.heading_text1 {
        padding: 0px 30px;
    }

    .termsAndConditionRTE {
        padding: 0px 15px;
    }

    h3.cm-heading.cm-main-heading.with-space-100.text- {
        padding: 0px 10px;
    }

}

.modal.show {
    opacity: 1;
}

button.btn.btn-primary {
    width: 238px;
    margin: 15px auto;
    background: #c4b67e;
    border-color: #c4b67e;
}

.modal-body {
    margin: auto !important;
    padding: 10px 30px !important;

}

/** Global Loader **/
.overlay-scroll {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    display: none;
    top: 0;
    overflow: hidden;
}

.overlay-scroll.active {
    display: block;
}

.loader-new {
    border: 16px solid #fff;
    border-radius: 50%;
    border-top: 16px solid #0000;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    top: 0;
}

.errClass {
    border-bottom: 1px solid red;
}

.nonErrorClass {
    border-bottom: 1px solid gray;
}

.loader-new.active {
    display: block;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cm-teasers-list-block.dynamicteaser .owl-stage {
    margin: 0 auto;
}

button#submitBecomeMemberBtnInfinia {
    margin-bottom: 20px;
}

p {
    font-family: 'ProximaNova';
    font-size: 16px;
}