body{
    font-family: EBGaramond;
}

.middle-section h1{
    font-size: 28px;
}

/* .middle-section p:nth-child(1){
    font-size: 16px;
    text-align: center;
} */

.middle-section h1:nth-child(2){
    font-size: 26px;
    text-align: left;
    padding:20px 0px 0px 0px;
}
.marriott-experience p{
    text-align: left;
    font-family: ProximaNova;
    font-size: 16px;
}

.room-facility p:nth-child(1){
    text-align: center;
    font-family: ProximaNova;
    font-size: 24px;
}
.room-facility h3{
    font-size: 26px;
    padding: 20px 0px 0px 0px;
}
.room-facility p{
    font-size: 16px;
}

.participating-hotels p{
    text-align: left;
    padding:20px 0px;
}
.term-and-contition h3{
    text-align: left;
    font-family: ProximaNova;
  font-size: 24px;
}
.term-and-contition ol li{
    text-align: left;
    font-family: ProximaNova;
    font-size: 16px;
}

.new-line {
    white-space: pre-line;
  }