
.cm-wrapper {
    margin: auto;
    max-width: 1190px;
    width: 100%;
    padding-right: 18px;
    padding-left: 18px;
    }
    .custom-hotel-list {
    border: 1px solid #979797;
    padding-top: 40px;
    margin: 70px 0 40px 0;
    position: relative
    }
    .custom-hotel-list-logo {
    width: 15.5%;
    position: absolute;
    top: -30px;
    left: calc(50% - 7.8%);
    background: #fff;
    text-align: center;
    height: 60px
    }
    .custom-hotel-list-logo img {
    height: 100%;
    width: auto
    }
    .custom-hotel-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0
    }
    .custom-hotel-row a {
    display: inline-block;
    padding: 0 18px;
    cursor: pointer
    }
    .custom-hotel-row img {
    max-height: 60px
    }
    @media only screen and (max-width:1040px) {
    .custom-hotel-row img {
    max-height: 50px
    }
    }
    @media only screen and (max-width:940px) {
    .custom-hotel-row img {
    max-height: 45px
    }
    .custom-hotel-row a {
    padding: 0 20px
    }
    .custom-hotel-list-logo {
    height: 44px;
    top: -22px
    }
    }
    @media only screen and (max-width:767px) {
      .cm-wrapper{width: 90%;}
    .appicon {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    }
    .viewcta {
    text-align: center;}
    .footer-sticky-links {
    width: 100%;
    text-align: center;
    margin: 25px 0
    }
    .top-footer {
    padding: 0
    }
    .top-footer-image {
    padding: 0
    }
    .footer-bottom {
    padding: 50px 0;
    border-top: 1px solid #e8e8e8
    }
    .custom-hotel-row img {
    max-height: 40px
    }
    .custom-hotel-row a {
    padding: 0 10px
    }
    .custom-hotel-row {
    padding: 30px 0
    }
    .custom-hotel-list-logo {
    height: 34px;
    top: -17px
    }
    .footer-section {
    margin-bottom: 0
    }
    .custom-hotel-list {
    margin: 0
    }
    .cm-links-list {
    margin-bottom: 0
    }
    }
    @media only screen and (max-width:675px) {
    .custom-hotel-row img {
    max-height: 30px
    }
    .custom-hotel-row a {
    padding: 0 12px
    }
    .custom-hotel-list-logo {
    height: 30px;
    top: -15px
    }
    }
    @media only screen and (max-width:560px) {
    
    .custom-hotel-row img {
    max-height: 20px
    }
    .custom-hotel-row a {
    padding: 0 10px
    }
    .custom-hotel-row {
    padding: 20px 0;
    flex-wrap: wrap
    }
    .custom-hotel-list-logo {
    height: 20px;
    top: -10px
    }
    }
    @media only screen and (max-width:320px) {
    .custom-hotel-row a {
    padding: 0 3px
    }
    }
    .custom-hotel-row a {
    display: inline-block;
    padding: 0 18px;
    cursor: pointer
    }
    @media only screen and (max-width:480px) {
    .custom-hotel-row a {
    display: inline-block;
    padding: 0 1.5%;
    cursor: pointer
    }
    .custom-hotel-row img {
    max-height: 20px
    }
    .custom-hotel-row {
    margin: 0 7px
    }
    }